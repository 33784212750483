import { Wallet, getWalletConnectConnector } from '@rainbow-me/rainbowkit';
export interface MyWalletOptions {
  projectId: string;
}
export const cdcDefi = ({ projectId }: MyWalletOptions): Wallet => ({
    id: 'f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d',
    name: 'Crypto.com | ONCHAIN',
    iconUrl: 'https://walletguide.walletconnect.network/_next/image?url=https%3A%2F%2Fapi.web3modal.com%2FgetWalletImage%2F88388eb4-4471-4e72-c4b4-852d496fea00%3FprojectId%3Dad53ae497ee922ad9beb2ef78b1a7a6e%26st%3Dwallet-guide%26sv%3D1.0.0&w=384&q=75',
    iconBackground: '#0c2f78',
    downloadUrls: {
      android: 'https://defi.onelink.me/pqA2',
      ios: 'https://defi.onelink.me/MFZQ',
      chrome: 'https://chrome.google.com/webstore/detail/cryptocom-wallet-extensio/hifafgmccdpekplomjjkcfgodnhcellj',
      qrCode: 'https://defi.onelink.me/MFZQ/download',
    },
    mobile: {
        getUri: (uri: string) => uri,
    },
    createConnector: getWalletConnectConnector({ projectId }),
});